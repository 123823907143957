/*
 * @Author: 月丶烨 1439904622@qq.com
 * @Date: 2024-06-24 23:30:43
 * @LastEditTime: 2024-06-27 16:26:25
 * @LastEditors: 月丶烨 1439904622@qq.com
 * @Description: 头部注释
 */
import axios from "axios";
axios.defaults.baseURL =process.env.NODE_ENV=='development'?'': process.env.VUE_APP_BASE_API;
export const getList = (path, userForm) => {
    return axios.get(`${path}`).then((res) => res.data).catch(error => {
      // 错误已在拦截器中处理，无需在此重复处理
      console.error('Request error:', error);
    });
  };