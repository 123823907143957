<!--
 * @Author: 月丶烨 1439904622@qq.com
 * @Date: 2024-06-23 10:59:13
 * @LastEditTime: 2024-06-29 03:59:15
 * @LastEditors: 月丶烨 1439904622@qq.com
 * @Description: 头部注释
-->
<template>
    <div>
        <el-carousel height="calc(100vh - 60px)" direction="vertical" motion-blur :autoplay="false"
            v-if="loopList.length">
            <el-carousel-item v-for="item in loopList" :key="`loopList${item._id}`">
                <div class="item" :style="{ backgroundImage: `url(${item.cover})` }">
                    <el-card class="box-card">
                        <template #header>
                            <div class="card-header">
                              <h2>{{ item.title }}</h2>
                            </div>
                        </template>
                        <div>{{ item.introduction }}</div>
                        <div class="detail">{{ item.detail }}</div>
                    
                    </el-card>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div v-else>
            <el-empty description="暂无产品" />
        </div>
    </div>

</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { getList } from "../httprequest/newsHttp.js"
import formatTime from "@/utils/formatTime.js"

const loopList = ref([])

onMounted(async () => {
    getProductList()
})
const getProductList = async () => {
    // 获取用户列表数据
    const res = await getList('/webapi/product/list')
    res?.data.map(e => {
        e.cover = process.env.VUE_APP_BASE_API + e.cover
    })
    loopList.value = res.data
}

</script>
<style lang="scss" scoped>
.item {
    width: 100%;
    height: 100%;
    background-size: cover;
    .box-card{
        width: 50%;
        height: 100%;
        background-color: rgba(255,255,255, 0.7);
        .detail,.more{
            margin-top: 20px;
        }
    }
}
</style>