<!--
 * @Author: 月丶烨 1439904622@qq.com
 * @Date: 2024-06-23 11:08:55
 * @LastEditTime: 2024-07-02 14:49:19
 * @LastEditors: 月丶烨 1439904622@qq.com
 * @Description: 头部注释
-->
<template>
    <div class="navbar">
        <el-menu :default-active="route.fullPath" class="el-menu-demo" mode="horizontal" router>
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/News">新闻中心</el-menu-item>
            <el-menu-item index="/Product">产品与服务</el-menu-item>
            <!-- <el-menu-item index="" @click="handleClick" >登陆</el-menu-item> -->
        </el-menu>
        <div class="right">企业门户官网</div>
    </div>


</template>
<script setup>
import { useRoute } from 'vue-router'
const route = useRoute()
const handleClick = () => {
   window.location.href = 'http://website.qiaobanglvyou.com/'
}

</script>
<style lang="scss" scoped>
.navbar{
    position: sticky;
    top: 0px;
    z-index: 1000;
}
.right{
    position: fixed;
    top: 0;
    right: 20px;
    widows:100px;
    height:59px;
    line-height: 59px;
    text-align: center;
}
</style>