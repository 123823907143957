/*
 * @Author: 月丶烨 1439904622@qq.com
 * @Date: 2024-06-23 11:46:35
 * @LastEditTime: 2024-06-25 01:06:22
 * @LastEditors: 月丶烨 1439904622@qq.com
 * @Description: 头部注释
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import News from '../views/News.vue'
import Product from '../views/Product.vue'
import NProgress from 'nprogress'
import NewsDetail from '@/views/NewsDetail.vue'

import 'nprogress/nprogress.css'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product
  },
  {
    path: '/NewsDetail/:id',
    name: 'NewsDetail',
    component: NewsDetail
  },
]



const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()

})

export default router
