<!--
 * @Author: 月丶烨 1439904622@qq.com
 * @Date: 2024-06-23 10:58:58
 * @LastEditTime: 2024-06-29 03:59:08
 * @LastEditors: 月丶烨 1439904622@qq.com
 * @Description: 头部注释
-->
<template>
    <div class="container">
        <div class="news-hreader" :style="{ backgroundImage: `url(${require('@/assets/huanleshizhe.png')})` }">
            <div class="news-hreader-title">侨帮新闻资讯</div>
            <div class="news-hreader-text">为您推荐公司及行业的前沿资讯，让您随时了解行业动态</div>
        </div>
        <div class="search">
            <el-popover placement="bottom" title="检索结果" width="50%" :visible="searchVisvble">
                <template #reference>
                    <el-input :prefix-icon="Search" v-model="searchInput" class="w-50 m-2" type="search" size="large"
                        placeholder="请输入新闻关键字" @input="searchVisvble = true" @blur="searchVisvble = false" />
                </template>
                <div v-if="searchNewsList.length > 0 && searchInput">
                    <div v-for="item in searchNewsList" :key="item._id" @click="searchItemClick(item._id)" class="search-item">
                        {{ item.title }}
                    </div>
                </div>
                <div v-else>
                    <el-empty description="没有搜索结果" :image-size="50" />
                </div>
            </el-popover>
        </div>
        <div class="top-news">
            <el-row :gutter="20" shadow="hover">
                <el-col v-for="item in topNewsList" :key="'topNewsList' + item._id" :span="6">
                    <el-card :body-style="{ padding: '0px' }" shadow="hover" @click="searchItemClick(item._id)" >
                        <div class="image" :style="{ backgroundImage: `url(${item.cover})` }"></div>
                        <div style="padding: 14px;">
                            <span>{{ item.title }}</span>
                            <div class="bottom">
                                <time class="time">{{ formatTime.getTime(item.editTime) }}</time>
                            </div>
                        </div>

                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div>
            <el-tabs v-model="whichTab" class="demo-tabs" style="margin: 20px;" >
                <el-tab-pane v-for="item in tabList" :label="item.label" :name="item.name" :key="'tabList' + item.name">
                    <el-row :gutter="20" shadow="hover">
                        <el-col :span="18">
                            <div v-for="data in tabeNews[item.name]" :key="'tabeNews' + data.id" class="tab-news-item">
                                <el-card :body-style="{ padding: '0px' }" shadow="hover"  @click="searchItemClick(data._id)">
                                    <div class="tab-news-image" :style="{ backgroundImage: `url(${data.cover})` }">
                                    </div>
                                    <div style="padding: 14px;float: left;">
                                        <span>{{ data.title }}</span>
                                        <div class="bottom">
                                            <time class="time">{{ formatTime.getTime(data.editTime) }}</time>
                                            <!-- <el-button text class="button">查看详情</el-button> -->
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <el-timeline style="max-width: 600px">
                                <el-timeline-item v-for="(data, index) in tabeNews[item.name]" :key="index"
                                    :timestamp="formatTime.getTime(data.editTime)">
                                    {{ data.title }}
                                </el-timeline-item>
                            </el-timeline>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-backtop :right="100" :bottom="100" />
    </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { getList } from "../httprequest/newsHttp.js"
import formatTime from "@/utils/formatTime.js"
import { useRouter } from 'vue-router'

import _ from 'lodash'
const router = useRouter()
let searchInput = ref()
let searchVisvble = ref(false)
let newsList = ref([])
const whichTab = ref(1)

const tabList = [
    {
        label: '最新动态',
        name: 1,

    },
    {
        label: '典型案例',
        name: 2
    }, {
        label: '通知公告',
        name: 3
    },
]

const tabeNews = computed(() => {
    return _.groupBy(newsList.value, item => item.category)
})


const getListList = async () => {
    // 获取用户列表数据
    const res = await getList('/webapi/news/list')
    // const admin = await getList('/webapi/user/addUser?username=admin&password=1234567')
    res?.data.map(e => {
        e.cover = process.env.VUE_APP_BASE_API + e.cover
    })
    newsList.value = res.data
}

const searchNewsList = computed(() => {
    return newsList.value.filter(item => item.title.includes(searchInput.value))
})

const topNewsList = computed(() => {
    return newsList.value.slice(0, 4)
})

onMounted(async () => {
    getListList()
})

const searchItemClick = (id) => {
    router.push(`/NewsDetail/${id}`)
}


</script>
<style lang="scss" scoped>
.container {
    position: relative;

    .time {
        font-size: 13px;
        color: gray;
    }
}

.news-hreader {
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .news-hreader-title {
        color: #fff;
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 3rem;
        font-weight: 900;
    }

    .news-hreader-text {
        color: #fff;
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.6rem;
        font-weight: 900;
    }
}

.search {
    position: absolute;
    top: 400px;
    width: 100%;
    text-align: center;

    .el-input {
        width: 50%;
        margin: 0 auto;
    }

}

.search-item {
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    padding-left: 1rem;

    &:hover {
        background-color: whitesmoke;
        color: rgb(226, 129, 129);
        font-size: 1.1rem;
    }
}

.top-news {
    margin: 20px;

    .image {
        width: 100%;
        height: 150px;
        background-size: cover;
    }
}

.tab-news-item {
    padding: 10px;

    .tab-news-image {
        width: 150px;
        height: 100px;
        background-size: cover;
        float: left;
    }
}
</style>