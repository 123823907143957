/*
 * @Author: 月丶烨 1439904622@qq.com
 * @Date: 2024-06-13 17:49:35
 * @LastEditTime: 2024-06-13 17:55:17
 * @LastEditors: 月丶烨 1439904622@qq.com
 * @Description: 头部注释
 */
//时间格式显示库
import moment from 'moment'
 moment.locale("zh-cn")

const formatTime = {
    getTime:(date)=>{
        // 格式化时间
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
    }

}

export default formatTime;