<!--
 * @Author: 月丶烨 1439904622@qq.com
 * @Date: 2024-06-23 10:57:37
 * @LastEditTime: 2024-07-02 14:47:58
 * @LastEditors: 月丶烨 1439904622@qq.com
 * @Description: 头部注释
-->
<template>
    <div class="container">
        <el-carousel height="calc(100vh - 60px)">
            <el-carousel-item v-for="(item, index) in loopList" :key="index+'carousel-item'" >
                <div class="carousel-item-product" :style="{ backgroundImage: `url(${item.cover})` }">{{ item.title }}</div>
            </el-carousel-item>
        </el-carousel>

    </div>


</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { getList } from "../httprequest/newsHttp.js"
import formatTime from "@/utils/formatTime.js"

const loopList = ref([])

onMounted(async () => {
    getProductList()
})
const getProductList = async () => {
    // 获取用户列表数据
    const res = await getList('/webapi/product/list')
    res?.data.map(e => {
        e.cover = process.env.VUE_APP_BASE_API + e.cover
    })
    loopList.value = res.data
}
</script>
<style lang="scss" scoped>
    .el-carousel {
        width: 100%;
        height: 100%;
     .carousel-item-product{
        width: 100%;
        height: 100%;
        background-size:contain;
     }
    }
</style>