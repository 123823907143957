<template>
    <el-row>
        <el-col :span="17" :offset="1">
            <div>
                <h2>{{ newsDerail.title }}</h2>
            </div>
            <div class="time">{{ formatTime.getTime(newsDerail.editTime) }}</div>
            <el-divider>
                <el-icon><star-filled /></el-icon>
            </el-divider>
            <div v-html="newsDerail.content">
            </div>
        </el-col>
        <el-col :span="5" :offset="1">
            <el-card class="box-card">
                <template #header>
                    <div class="card-header">
                        <span class="card-header-text">最近新闻</span>
                    </div>
                </template>
                <div v-for="item in topNewsList" :key="`topNewsList${item._id}`" class="top-news"
                    @click="searchItemClick(item._id)">
                    <div class="image" :style="{ backgroundImage: `url(${item.cover})` }"></div>
                    <div style="padding: 14px;">
                        <span>{{ item.title }}</span>
                        <div class="bottom">
                            <time class="time">{{ formatTime.getTime(item.editTime) }}</time>
                        </div>
                    </div>
                </div>
            </el-card>

        </el-col>
    </el-row>
    <el-backtop :right="100" :bottom="100" />
</template>
<script setup>
import { onMounted, ref, watchEffect, onBeforeMount } from "vue"
import { getList } from "../httprequest/newsHttp.js"
import formatTime from "@/utils/formatTime.js"
import { useRouter, useRoute } from "vue-router"
import { StarFilled } from '@element-plus/icons-vue'
const router = useRouter()
const route = useRoute()

const newsDerail = ref({})
const topNewsList = ref([])
//监听更新
watchEffect(async () => {
    console.log(111,route.params.id);
    const res1 = await getList(`/webapi/news/list/${route.params.id}`)
    const res2 = await getList(`/webapi/news/list?pageSize=4`)
    res2.data.map(e => {
        e.cover = process.env.VUE_APP_BASE_API + e.cover
    })
    newsDerail.value = res1.data[0]
    topNewsList.value = res2.data
})

const searchItemClick = (id) => {
    router.push(`/NewsDetail/${id}`)
}


</script>


<style lang="scss" scoped>
.el-row {
    margin-top: 30px;
}

.time {
    font-size: 13px;
    color: gray;
}

.box-card {
    .card-header {
        .card-header-text {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.top-news {
    margin: 20px;
    display: flex;

    .image {
        width: 60px;
        height: 50px;
        background-size: cover;
    }
}
</style>