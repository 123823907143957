/*
 * @Author: 月丶烨 1439904622@qq.com
 * @Date: 2024-06-23 10:47:07
 * @LastEditTime: 2024-06-25 00:19:29
 * @LastEditors: 月丶烨 1439904622@qq.com
 * @Description: 头部注释
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

createApp(App).use(ElementPlus).use(store).use(router).mount('#app')
